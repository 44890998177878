import React, {useState} from "react";
import EventStore from "../api/eventStore";
import Popup from "./popup";
import {observer} from "mobx-react-lite";
import dateformat from "dateformat";
dateformat.i18n = {
    dayNames: [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
        'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
    ],
    monthNames: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
    ],
    timeNames: [
        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
    ]
};

const EventList = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [activeDay, setActiveDay] = useState("");
    const { plainEvents } = EventStore;
    const getHeader = (year: string, month: string) => {
        const monthDate = new Date(parseInt(year), parseInt(month)-1, 1);
        return dateformat(monthDate, "mmmm yyyy");
    }

    const getDay = (year: string, month: string, day: number) => {
        const dayDate = new Date(parseInt(year), parseInt(month)-1, day, 1);
        return dateformat(dayDate, "dddd 'der' d");
    }

    const setActive = (dayKey: string) => {
        //setShowPopup(dayKey !== activeDay);
        setActiveDay(dayKey === activeDay ? "" : dayKey);
    }

    const popupAction = (confirm: boolean) => {
        if (confirm) {
            if (window.parent.location.href.indexOf("kontakt") === -1) {
                window.parent.location.href = "https://montage-pro.de/kontakt#" + activeDay;
            } else {
                window.parent.location.href = "https://montage-pro.de/kontakt#" + activeDay;
            }
        }
        setShowPopup(false);
        setActiveDay("");
        console.log(confirm);
    }
    const ShowPopup = () => {
        if (showPopup) {
            return <Popup/>
        }
        return <div></div>;
    }

    const ShowLoading = () => {
        if (!plainEvents || Object.keys(plainEvents).length === 0) {
            return <div className="loader">Lade Termine...</div>
        }
        return <div></div>;
    }

    const key = (year: string, month: string, day: number = 0): string => {
        return [year, parseInt(month) - 1, day].join(".")
    }

    return (
        <div className="content">
            <ShowLoading/>
            <div className="popup-container">
                <ShowPopup/>
            </div>
            {
                Object.keys(plainEvents).map((year: string) => (
                    Object.keys(plainEvents[year]).map((month: string) => (
                        <div className="month" key={key(year,month)}>
                            <div className="header shadow">
                                {getHeader(year, month)}
                            </div>
                            <div className="break"></div>
                            {
                                plainEvents[year][month].map((day: number) => (
                                    <div className={
                                        `day shadow ${activeDay === key(year, month, day) ? "active" : ""}`
                                    } key={key(year, month, day)} onClick={() => setActive(key(year, month, day))}>
                                        {getDay(year, month, day)}.
                                    </div>
                                ))
                            }
                        </div>
                    ))
                ))
            }
        </div>
    );
};

export default observer(EventList);
