import React from "react";
import {observer} from "mobx-react-lite";

const Popup = (props: any) => {
    return (
        <div className="popup shadow">
            <div className="popup-header">
                Möchten Sie einen Termin vereinbaren?
            </div>
            <div className="popup-buttons">
                <div className="yes-button" onClick={() => props.onAction(true)}>Ja</div>
                <div onClick={() => props.onAction(false)}>Nein</div>
            </div>
        </div>
    );
};

export default observer(Popup);
