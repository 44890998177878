import React from "react";
import EventStore from "./api/eventStore";
import EventList from './components/list';
import './App.css';

EventStore.getEvents();

function App() {
  return (
    <div className="App">
        <EventList></EventList>
    </div>
  );
}

export default App;
