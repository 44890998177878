import { observable, action, runInAction } from "mobx"

export interface FreeEvents {
  [year: string]: {[month: string]: number[]}
}

class EventStore {
    @observable plainEvents: FreeEvents = {};
    @observable state = "pending" // "pending" / "done" / "error"

    @action getEvents = async () => {
        try {
            const events: FreeEvents|undefined = await this.fetchEvents();
            // after await, modifying state again, needs an actions:
            runInAction(() => {
                this.state = "done";
                if (events) {
                    this.plainEvents = events;
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error";
            })
        }
    }

    private async fetchEvents() {
        try {
            const response = await fetch(
                "https://calendar.montage-pro.de/api"
            )
            return response.json() as Promise<FreeEvents>;
        }
        catch(ex) {
            console.log(ex);
        }
    }
}

export default new EventStore();
